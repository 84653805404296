var searchesID = "AA_FlightSearch";

function getRecentSearchesFromLS() {
    moveRecentSearchesFromCookieToLS();
    var recentSearches = [];
    if (window['localStorage'] !== null) {
        var searches = localStorage.getItem(searchesID);
        if (searches != null && searches != "") {
            recentSearches = JSON.parse(searches);
        }
        else {
        	recentSearches = [];
        }
    }
    else {
        recentSearches = [];
    }
    return recentSearches;
}

function saveRecentSearchesToLS(recentSearches){
    localStorage.setItem(searchesID, recentSearches);
    return;
}

function deleteRecentSearchesFromLS(){
    localStorage.removeItem(searchesID);
    return;
}

function submitSearch(currentSearch) {
    try {
        saveRecentSearchesToLS(JSON.stringify(updateRecentSearches(currentSearch, getRecentSearchesFromLS())));
    }
    catch (err) {
        console.log("Failed to add current search to LS.");
    }
    return;
}

function moveRecentSearchesFromCookieToLS(){
    var aaCookieSearches = jQuery.aaCookie.get(searchesID);
    if (aaCookieSearches != null) {
        if (aaCookieSearches.length > 0) {
            saveRecentSearchesToLS(aaCookieSearches);
        }
        document.cookie = searchesID + '="";-1; path=/';
    }
}
